import SiteWrapper from "../../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../../components/mdrender";
import * as md from "../../../components/mdrender.module.sass";
import TitleBlock from "../../../components/title";

import "./mobile-incident-management@gen.scss";

const GeneratedProductFeaturesMobileIncidentManagement = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          モバイルでのインシデント管理 - PagerDuty正規代理店 - 株式会社Digital
          Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="モバイルでのインシデント管理。どんなときも。 どこでも。
"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="モバイルでのインシデント管理。どんなときも。 どこでも。
"
        />

        <meta
          property="og:title"
          content="モバイルでのインシデント管理 - PagerDuty正規代理店 - 株式会社Digital Stacks"
        />

        <meta
          property="og:description"
          content="モバイルでのインシデント管理。どんなときも。 どこでも。
"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/product/features/mobile-incident-management/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/product/features/mobile-incident-management/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Products"
        textGreen="モバイルでのインシデント管理"
        overview=""
      />

      <div className="mimWrapper">
        <div className="center-2 first-section">
          <h2 className="center-title">
            モバイルでのインシデント管理。
            <br />
            どんなときも。 どこでも。
          </h2>
          <p className="center-para">
            すべてのデバイスで、卓越したユーザーエクスペリエンスを備えたインシデント管理ライフサイクルを実現します。
            PagerDuty
            Mobileアプリケーションを使用すると、アラートを視覚化し、外出先でアラートを確認、解決、再割り当てすることができます。
            モバイルアプリ、メール、SMS、電話などのマルチチャネル通信オプションを使用すると、アラートやインシデントの更新を見逃すことがありません。
            <br />“ iOSアプリが本当に好きです。
            オフィス外にいる間にすべてのインシデントを認知し、エスカレートし、解決する能力は本当に素晴らしいです。
            “
          </p>
        </div>
        {/* details */}
        <div className="center-1">
          <h2 className="center-title">
            モバイルインシデント管理の詳細をご覧ください
          </h2>
        </div>
        <div className="three-col-without-cta">
          <div className="card">
            <div className="title">
              <p>インシデントを作成する</p>
            </div>
            <p>
              モバイルアプリから直接新しいインシデントを簡単に作成できます。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>モバイルアプリ</p>
            </div>
            <p>
              iOSとAndroidの携帯電話、タブレット、スマートウォッチでインシデントを管理します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>アラートと通知</p>
            </div>
            <p>
              アラート音をカスタマイズして、無制限のプッシュ通知アラートを受信することができます。また、割り当てられたインシデントについて承認、エスカレート、解決されたことを知ることができます。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>インシデントの詳細</p>
            </div>
            <p>
              インシデントの詳細、インシデント履歴、オンコールスケジュールを表示します。
              オープンしているインシデントに対して、認識、解決、再割り当てすることができます。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>スケジューリング</p>
            </div>
            <p>
              自分またはチームのオンコールスケジュールとブックされたオーバーライドを表示します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>インシデントをスヌーズする</p>
            </div>
            <p>すぐに解決する必要のないアラートをスヌーズします。</p>
          </div>
        </div>
        <div className="conclusion">
          PagerDutyのインシデント解決プラットフォームは、ノイズを削減し、
          <br />
          インシデントをより迅速に解決するのに役立ちます。
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedProductFeaturesMobileIncidentManagement;
